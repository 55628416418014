<script>
import leftTree from './components/leftTree';
import rightTable from './components/rightTable';

export default {
    data() {
        return {};
    },
    methods: {
        nodeSelect(params) {
            this.$refs.tableRef.getList(params, true);
        }
    },
    components: {
        leftTree,
        rightTable
    }
};
</script>
<template>
    <div class="directive-container">
        <el-row :gutter="10">
            <el-col :span="6">
                <leftTree @nodeSelect="nodeSelect"></leftTree>
            </el-col>
            <el-col :span="18">
                <rightTable ref="tableRef"></rightTable>
            </el-col>
        </el-row>
  </div>
</template>
<style lang="scss" scoped>
.directive-container {
    padding: 10px;
    height: 100%;
}
</style>
<style lang="scss">
.directive-container {
    .el-row, .el-col {
        height: 100%;
    }
}
</style>
