<template>
  <el-dialog
    :title="modalTitle"
    :visible="dialogVisible"
    width="450px"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="90px"
      class="form"
      :rules="formRules"
    >
      <el-form-item
        v-for="f in eidtableFormItems"
        :key="f.prop"
        :label="f.label"
        :prop="f.prop"
      >
        <el-cascader
          v-model="form[f.prop]"
          :options="treeDatas"
          clearable
          :props="{
            label: 'name',
            children: 'children',
            value: 'id',
            checkStrictly: true,
          }"
          placeholder="请选择"
          v-if="f.prop == 'parent_id'"
        ></el-cascader>
        <el-input
          v-else
          v-model.number="form[f.prop]"
          :placeholder="f.placeholder"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确认保存</el-button>
      <el-button @click="close">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      form: this.initForm(),
      eidtableFormItems: [
        {
          prop: "parent_id",
          label: "上级名称",
        },
        {
          prop: "name",
          label: "名称",
          placeholder: "请输入名称",
        },
        {
          prop: "sort",
          label: "顺序",
          placeholder: "请输入顺序",
        },
      ],
      formRules: {
        name: [{ required: true, message: `请输入名称`, trigger: "blur" }],
        parent_id: [
          { required: true, message: "请选择上级名称", trigger: "blur" },
        ],
        sort: [{ required: true, message: `请输入顺序`, trigger: "blur" }],
      },
      modalTitle: ''
    };
  },
  computed: {
    ...mapGetters(["directiveTreeDatas"]),
    treeDatas() {
      let datas = JSON.parse(JSON.stringify(this.directiveTreeDatas));
      datas.map((dataItem) => {
        if (dataItem.children && dataItem.children.length) {
          dataItem.children.map((item) => {
            item.children = null;
          });
        }
      });
      return datas;
    }
  },
  methods: {
    ...mapActions([
      "addOrUpdateTreeData"
    ]),
    show(editData) {
      this.dialogVisible = true;
      if (editData) {
        this.modalTitle = '编辑';
        this.form = editData;
      } else {
        this.modalTitle = '添加';
        this.form = this.initForm();
      }
    },
    initForm() {
      return {
        id: null,
        name: '',
        parent_id: [],
        sort: ''
      };
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const ret = await this.addOrUpdateTreeData({
            ...this.form,
            parent_id: this.form.parent_id.length > 1 ? this.form.parent_id[1] : this.form.parent_id[0]
          });
          if (ret && ret.status === 'success') {
            this.close();
            this.$emit('confirm');
          } else {
            this.$message.error(ret && ret.msg);
          }
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='scss'>
.form {
  background-color: #fff;
  .el-cascader--small {
    width: 100%;
  }
  .el-form-item__content {
    .el-select {
      display: block;
    }
  }
}
</style>